import React from 'react'
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom'

import { useTheme } from '@material-ui/core/styles'

import AboutUs from 'components/AboutUs'
import Concept from 'components/Concept'
import CustomButton from 'components/CustomButton'
import FAQ from 'components/FAQ'
import SupportList from 'components/SupportList'
import HowItWorks from 'components/HowItWorks'
import MainImage from 'components/MainImage'
import Reward from 'components/Reward'
import EventList from 'pages/EventList'
import NewsList from 'pages/NewsList'

export default function Top() {
  const { t } = useTranslation();
  const { palette, spacing } = useTheme()
  const { push } = useHistory()

  const More = ({ text, href }) => (
    <div
      style={{
        marginBottom: spacing(6),
        background: palette.background.paper,
        padding: spacing(3),
      }}
    >
      <CustomButton
        arrow
        onClick={() => {
          if (href.startsWith('/')) {
            push(href)
          } else {
            window.open(href)
          }
        }}
      >
        {text}
      </CustomButton>
    </div>
  )

  return (
    <div style={{ background: palette.background.default }}>
      <MainImage src="/images/main.jpg" alt="トップ" type="top" />
      <Concept />
      {Boolean(process.env.REACT_APP_CONCEPT_HREF) && (
        <More text="詳細を見る" href={process.env.REACT_APP_CONCEPT_HREF} />
      )}
      <NewsList limit={3} />
      <More text={t("Word.NewsList")} href="/news" />
      <HowItWorks />
      {process.env.REACT_APP_ACCOUNT_ID === '37' && (
      <React.Fragment>
      <div>
          <img style={{objectFit: 'contain', width: '100%', marginBottom: spacing(5)}} alt="" src={"/images/how-to.png"}/>
      </div>
      <div>
          <img style={{objectFit: 'contain', width: '100%', marginBottom: spacing(5)}} alt="" src={"/images/top-step-up.jpg"}/>
      </div>
      </React.Fragment>
      )}
      <Reward />
      <EventList limit={7} />
      <More text={t("Word.EventsList")} href="/events" />
      <FAQ />
      {process.env.REACT_APP_ACCOUNT_ID !== '37' && (
        <SupportList/>
      )}
      <AboutUs />
    </div>
  )
}
